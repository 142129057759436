import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import PropTypes from "prop-types";

import ReactMarkdown from "react-markdown";

import Layout from "../components/Layout";



export const MentionsLegalesTemplate = ({page}) => {
  const content = page.frontmatter;

    return (
      <div className="section">
        <div className="content-wrapper xx-small">
          <h1 className="title-3 section-title text-medium mgb-s">{content.title}</h1> 
          {content.paragraphs.map((paragraph, index) => (
            <div key={index} className="paragraph-spacing markdown-text mgt-m">
              <h2 className="title-5 bold-weight text-light mgb-xs">{paragraph.title}</h2>
              <ReactMarkdown children={paragraph.textContent}/>
            </div>
          ))}
        </div>
      </div>
    )
}


const MentionsLegales = ({data}) => {
  const { markdownRemark: page, footerData, navbarData } = data;
  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet>
        <title>Mentions légales</title>
      </Helmet>
      <MentionsLegalesTemplate page={page}/>
    </Layout>
  )
}

MentionsLegales.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MentionsLegales;


export const mentionsLegalesQuery = graphql`
  query MentionsLegales($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        paragraphs {
          title
          textContent
        }
      }
    }
    ...LayoutFragment
  }
`